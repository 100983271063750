.site-header {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.site-header__contents,
.main-nav__header {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-logo {
  display: block;
  width: 327px;
  max-width: 40vw;
  background-repeat: no-repeat;
  background-size: cover;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  &:hover {
    svg {
      opacity: 0;
    }

    animation: animate-logo .5s infinite;
  }
}

body.nav-expanded .site-logo:hover {
  animation: animate-logo-white .5s infinite;
}

@keyframes animate-logo {
  0% {
    background-image: url("../images/handwritten/d-shrig--alt1.svg");
  }

  25% {
    background-image: url("../images/handwritten/d-shrig--original.svg");
  }

  50% {
    background-image: url("../images/handwritten/d-shrig--alt2.svg");
  }

  75% {
    background-image: url("../images/handwritten/d-shrig--original.svg");
  }

  100% {
    background-image: url("../images/handwritten/d-shrig--alt1.svg");
  }
}

@keyframes animate-logo-white {
  0% {
    background-image: url("../images/handwritten/d-shrig--alt1--white.svg");
  }

  25% {
    background-image: url("../images/handwritten/d-shrig--original--white.svg");
  }

  50% {
    background-image: url("../images/handwritten/d-shrig--alt2--white.svg");
  }

  75% {
    background-image: url("../images/handwritten/d-shrig--original--white.svg");
  }

  100% {
    background-image: url("../images/handwritten/d-shrig--alt1--white.svg");
  }
}

.site-header__ctas {
  display: flex;
  align-items: center;
}

.site-header__social {
  margin: 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  svg {
    mix-blend-mode: multiply;
  }

  a {
    display: block;
    width: 20px;
    height: 20px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  li:not(:last-child) {
    margin-right: 1rem;
  }
}

.nav-button {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
}

body:not(.custom-cursor-enabled) {
  .nav-button,
  #closeNav {
    cursor: pointer;
  }
}

.nav-button,
.main-nav__actions {
  font-size: 2rem;
}

.main-nav {
  display: none;
  // display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $black;
  color: $white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 99;
}

.main-nav__header {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: $black;
}

.main-nav__actions {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  li:not(:last-child) {
    margin-right: 2rem;
  }
}

.main-nav__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 2.5rem;
}

.main-nav__menu {
  margin: 0 0 3rem 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    font-size: 2rem;
  }

  li.main-nav__label {
    font-size: 1.6rem;
    color: $grey;
    margin-bottom: .8rem;
  }

  a {
    display: inline-block;
    line-height: 1.4;
  }

  a[href="/"] {
    text-decoration: line-through;
  }
}

// Subtle offsetting
.main-nav__menu--work {
  li:nth-child(2) a {
    margin-left: .5rem;
    transform: rotate(-2deg);
  }

  li:nth-child(3) a {
    transform: rotate(1deg);
  }

  li:nth-child(5) a {
    margin-left: -.4rem;
    transform: rotate(2deg);
  }

  li:nth-child(6) a {
    margin-left: .1rem;
  }

  li:nth-child(7) a {
    margin-left: .7rem;
  }

  li:nth-child(9) a {
    transform: rotate(-3deg);
  }

  li:nth-child(10) a {
    margin-left: -.3rem;
    transform: rotate(1.2deg);
  }
}

.main-nav__menu--other-stuff {
  li:nth-child(3) a {
    transform: rotate(1deg);
  }

  li:nth-child(4) a {
    margin-left: -.4rem;
    transform: rotate(-2deg);
  }
}

.main-nav__menu--nav-items {
  li {
    font-size: 3rem;
  }

  li:nth-child(1) a {
    margin-left: .6rem;
    transform: rotate(-4deg);
  }

  li:nth-child(4) a {
    font-size: 1.4em;
    margin-top: .4rem;
    margin-left: .8rem;
    transform: rotate(-3deg);
  }
}

.search-icon {
  display: inline-block;
  width: 20px;
  height: auto;
  margin-right: 1rem;
  transform: translateY(3px);
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .site-header__contents,
  .main-nav__header,
  .main-nav__body {
    padding: 4rem;
  }

  .nav-button,
  .main-nav__actions {
    font-size: 3rem;
  }

  .main-nav__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .main-nav__menu {
    li {
      font-size: 3rem;
    }
  }

  .main-nav__menu--work,
  .main-nav__menu--other-stuff {
    grid-column: 1 / 2;
  }

  .main-nav__menu--nav-items {
    margin-top: 10vh;
    grid-column: 2 / 3;
    grid-row: 1 / 3;

    li {
      font-size: 5rem;
    }
  }

  .site-header__social {
    margin: 0 30px;

    a {
      width: 30px;
      height: 30px;
    }
  }
}
