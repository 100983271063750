* {
  // outline: 1px solid red;
}

body {
  display: flex;
  flex-direction: column;

  > main {
    flex-grow: 1;
  }
}

main {
  padding-top: 4rem;
  padding-bottom: 15rem;
}

main.full-height {
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;

  > .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    > iframe {
      flex-grow: 1;
    }
  }
}

.site-header,
.site-footer {
  flex-grow: 0;
}

.container {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.container--sm {
  max-width: 76rem;
}

.container--md {
  max-width: 112rem;
}

.container--lg {
  max-width: 244rem;
}

.standard-entry__content {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 3rem;
}

.grid--align-centre {
  align-items: center;
}

@for $i from 1 through 12 {
  .col-#{$i} {
    grid-column: span $i;
  }
}

.fc--nbm > *:first-child {
  margin-top: 0;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      grid-column: span $i;
    }
  }
}

.site-search {
  margin-bottom: 6rem;
  input {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .container {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .grid {
    gap: 5rem;
  }

  @for $i from 1 through 12 {
    .col-md-#{$i} {
      grid-column: span $i;
    }
  }
}
