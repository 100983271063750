.work-entry__video-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.work-entry__video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  border: none;
}

.work-entry__video-upload {
  display: block;
  width: 100%;
  height: auto;
}

.work-entry__actions {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem -.5rem -.5rem;

  .button {
    margin: .5rem;
  }
}
