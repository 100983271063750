// Colours
$black: #000;
$green: #06f216;
$white: #fff;
$grey: #bdbdbd;

// Breakpoints
$breakpoints: (
  sm: 640px,
  md: 768px,
  lg: 961px,
  xl: 1200px,
  xxl: 1400px,
);
