.alert-bar {
  background-color: rgba($black, .2);
  padding: .8rem 0;
  text-align: center;
  text-decoration: none;

  strong {
    animation-name: pulseText;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-right: .8rem;
      background-color: currentColor;
      display: inline-block;
      transform: translateY(-2px);
    }
  }

  .shrig-hand {
    font-size: 3rem;
    color: #fff;
    margin: 0 .4rem;
    display: inline-block;
    text-shadow: 0px 2px $black;
  }

  svg {
    display: inline-block;
    animation-name: poke;
    animation-duration: .5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
}

@keyframes pulseText {
  from {
    color: currentColor;
  }

  to {
    color: red;
  }
}

@keyframes poke {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(2px);
  }
}

.alert-bar:hover {
  background-color: $black;
  color: $white;

  svg path {
    fill: $white;
  }
}
