.button {
  display: inline-flex;
  align-items: baseline;
  font-size: 2.8rem;
  line-height: 4rem;
  text-decoration: none;
  background-color: $black;
  color: $white;
  padding: .6rem 2rem .8rem;
  transition: box-shadow .3s;
  border: 0;

  &:not(.button--disabled):hover {
    background-color: $white;
    color: $black;
    box-shadow: inset 0 0 0 3px $black;
  }
}

.button--white {
  background-color: $white;
  color: $black;

  &:not(.button--disabled):hover {
    background-color: $black;
    color: $white;
    box-shadow: inset 0 0 0 3px $white;
  }
}

.button--disabled {
  cursor: not-allowed;
  opacity: .5;
}

.buy-button {
  margin-top: 3rem;
}

.button--small {
  font-size: 2.4rem;
  line-height: 3.4rem;
}

.cc-btn:hover {
  text-decoration: none !important;
}
