@font-face {
  font-family: "DavidShrigley";
  src: url("/dist/fonts/DavidShrigley.ttf") format("truetype");
}

@font-face {
  font-family: "Shrigley-Regular";
  src: url("/dist/fonts/Shrigley-Regular.woff2") format("woff2");
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto Mono", monospace;
  font-size: 1.8rem;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "pnum" on, "lnum" on, "ss04" on;
}

%shrig-hand {
  font-family: "Shrigley-Regular", sans-serif;
  font-weight: 400;
  line-height: 0.9;
  letter-spacing: -.07em;
}

.shrig-hand,
h1,
h2,
h3,
.subtitle,
.button,
.breadcrumb {
  @extend %shrig-hand;
}

h1 {
  margin-bottom: 50px;
  text-align: center;
}

h1.page-title {
  font-size: 4.8rem;
  margin-bottom: 3rem;
  text-align: left;
}

h2 {
  font-size: 4rem;
  margin-top: 3rem;
  margin-bottom: 3.6rem;
}

h3 {
  font-size: 2.8rem;
  margin-top: 2.1rem;
  margin-bottom: 2.4rem;
}

.mono {
  font-family: "Roboto Mono", monospace;
}

p {
  margin-bottom: 1.6rem;
}

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-thickness: 2px;
  text-underline-offset: 6px;
  text-decoration-color: rgba($black, 30%);
  transition: all .3s;

  &:hover {
    text-decoration-color: rgba($black, 100%);
  }
}

a[role="button"] {
  text-decoration: none;
}

.subtitle {
  display: block;
  font-size: 2rem;
}

h1 + .subtitle {
  margin-top: -2rem;
  margin-bottom: 3rem;
}

.work-entry__content--no-description h1 + .subtitle {
  margin-bottom: 0;
}

.standfirst {
  margin-top: 1.6rem;
}

.listing-intro {
  margin-bottom: 5rem;
}

.text-align--center {
  text-align: center;
}

.tippy-box[data-theme~="shrigley"] {
  background-color: $black;
  @extend %shrig-hand;
  font-size: 2.4rem;
  text-align: center;
}

@keyframes wobb {
  0%, 100%   {transform: translateY(0px)}
  25%  {transform: translateY(-3px)}
  75%  {transform: translateY(3px)}
}

.wobble span {
  animation-name: wobb;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 400ms;
  display: inline-block;
  transform: translateY(0px);
}

@media screen and (min-width: map-get($breakpoints, md)) {
  h1 {
    font-size: 8rem;
  }
}
