.filter {
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    margin-right: 1rem;
  }
}

h1 + .filter {
  margin-top: -2rem;
}

@media (min-width: map-get($breakpoints, md)) {
  .filter {
    position: absolute;
    bottom: 0;
    right: 4rem;
    margin-bottom: 1rem;
  }

  h1 + .filter {
    margin-top: 0;
  }
}
