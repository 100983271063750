@media (pointer: fine) {
  body.custom-cursor-enabled {
    * {
      cursor: none;
    }

    select {
      cursor: auto;
    }

    .cursor {
      width: 82px;
      height: 90px;
      position: fixed;
      pointer-events: none;
      left: 34px;
      top: 38px;
      z-index: 1001;
      background-image: url("../images/icons/cursor-hand.png?v=150822");
      background-repeat: no-repeat;
      background-position: top left;
      background-size: 100% auto;
      transition: opacity .15s;
      opacity: 0;
    }

    .cursorinit {
      opacity: 1;
    }

    .cursoractive {
      background-image: url("../images/icons/cursor-hand--active.png?v=150822");
    }

    .cursor2 {
      width: 0;
      height: 0;
      opacity: 0;
      border-radius: 100%;
      background-color: black;
      position: fixed;
      transition: width .15s, height .15s, opacity .15s;
      transform: translate(-50%, -50%);
      pointer-events: none;
      z-index: 1000;
    }

    .cursorinnerhover {
      width: 40px;
      height: 40px;
      opacity: .5;
    }

    .cursorinneractive {
      width: 20px;
      height: 20px;
      opacity: 1;
    }

    .nav-expanded {
      .cursor2 {
        background-color: white;
      }
    }
  }
}
