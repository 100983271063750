.breadcrumb {
  ol {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-flex;
  }

  li:not(:last-child) {
    margin-right: .8rem;

    &:after {
      content: "/";
      margin-left: .8rem;
      font-family: "Roboto Mono", monospace;
    }
  }

  a {
    display: block;
  }
}

.standard-entry .breadcrumb {
  margin-bottom: 3rem;
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .standard-entry .breadcrumb {
    margin-bottom: 5rem;
  }
}
