.card {
  position: relative;
  transition: box-shadow 100ms ease-in-out;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, .2);
    opacity: 0;
    transition: opacity 200ms;
  }

  &:hover {
    box-shadow: 0px 0px 20px rgba(44, 49, 67, 0.2);

    &:before {
      opacity: 1;
    }
  }
}

.card--tooltip-off {
  &:before {
    background-color: rgba($black, .85);
  }

  &:hover {
    .card__title {
      opacity: 1;
    }
  }
}

.card__title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  color: $white;
  text-align: center;
  z-index: 1;
  @extend %shrig-hand;
  padding: 1.6rem;
  font-size: 5rem;
  opacity: 0;
  transition: opacity 200ms;
}
