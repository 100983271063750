.missing-image {
  width: 100%;
  aspect-ratio: 5 / 7;
  background-color: rgba($black, .2);
  border-radius: .8rem;
  color: rgba($black, .5);
  display: flex;
  align-items: center;
  justify-content: center;
}
