.stepper {
  font-size: 1.2rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
}

.stepper__item {
  flex: 1;
  display: flex;

  a {
    display: inline-flex;
    align-items: center;
  }
}

.stepper__item--prev {
  svg {
    margin-right: 1.6rem;
  }

  &:hover svg {
    animation: bounce-left 1s ease infinite;
  }
}

.stepper__item--next {
  justify-content: flex-end;

  svg {
    margin-left: 1.6rem;
  }

  &:hover svg {
    animation: bounce-right 1s ease infinite;
  }
}

@keyframes bounce-right {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(.5rem);
  }

  0% {
    transform: translateX(0%);
  }
}

@keyframes bounce-left {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-.5rem);
  }

  0% {
    transform: translateX(0%);
  }
}

@media screen and (min-width: 800px) {
  .container--sm {
    .stepper__item svg {
      position: absolute;
      margin: 0;
    }

    .stepper__item--prev svg {
      left: -2rem;
    }

    .stepper__item--next svg {
      right: -2rem;
    }
  }
}

@media screen and (min-width: 1200px) {
  .container--md  {
    .stepper__item svg {
      position: absolute;
      margin: 0;
    }

    .stepper__item--prev svg {
      left: -2rem;
    }

    .stepper__item--next svg {
      right: -2rem;
    }
  }
}
