body {
  opacity: 0;
  background-color: var(--backgroundColour);
  color: $black;

  &.theme-loaded {
    opacity: 1;
  }
}

#themePicker {
  background-color: var(--backgroundColour);
  padding: 5px 2px;
}

body.nav-expanded .theming {
  background-color: var(--backgroundColour);
}

.theming {
  font-size: 1rem;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
  font-size: 1.4rem;
  z-index: 100;
  background-color: transparent;
  border-top-right-radius: .8em;
  background-color: var(--backgroundColour);

  label {
    font-size: inherit;
  }
}

.theming__field--cursor.form-control {
  display: none;
  margin-left: 2rem;

  @media (pointer: fine) {
    display: grid;
  }
}
