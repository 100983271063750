input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  padding: 10px;
  border: 2px solid $black;
  background-color: $white;
  border-radius: .8rem;
}

select {
  font-weight: 700;
}

.user-form {
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.6rem;
}

label {
  font-size: 1.6rem;
  font-weight: 700;
}

button[type="submit"] {
  margin-top: 3rem;
}

#hnypt {
  display: none;
}

.htmx-request {
  opacity: .5;
}

ul.errors {
  margin: 0;
  padding: 0;
  list-style: none;
  color: red;

  li {
    margin-top: .8rem;
  }
}

.form-control {
  display: grid;
  align-items: center;
  grid-template-columns: 1.5em auto;
  gap: 0.5em;

  input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.5em;
    height: 1.5em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    display: grid;
    place-content: center;

    &:before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-image: url("../images/icons/check.svg");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:checked:before {
      transform: scale(1);
    }
  }
}
