.vertical-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
}

.vertical-list-item {
  &:not(:last-child) {
    margin-bottom: 5rem;
  }
}

.vertical-list-item__contents {
  h3 + .subtitle {
    margin-top: -.5rem;
  }

  .button {
    margin-top: 1rem;
  }
}
