.infinite-scroll-indicator {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  span, svg {
    display: block;
  }

  svg {
    animation: bounce 1s linear infinite;
  }
}

#indicator {
  display: none;

  &.htmx-request {
    display: block;

    // Hide the 'scroll to load more' message if we are loading more
    + span {
      display: none;
    }
  }
}

@keyframes bounce {
  0% {
    transform: none;
    animation-timing-function:ease-out;
  }

  50% {
    transform: translateY(-10%);
    animation-timing-function:ease-in;
  }
}
